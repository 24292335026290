// import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';

// import { useAuth } from '../hooks/use-auth';

function Dashboard() {
    // const { session } = useAuth();



    return (
        <Layout>

            <div>You can see the dashboard </div>

        </Layout>
    );
}

export default Dashboard;
