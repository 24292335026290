import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './hooks/use-auth';
// Import your page components
// import Home from './pages/home';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Category from './pages/category';
// import Session from './pages/session';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from "./components/ui/toaster"


function App() {
  return (
    <div>

      <Router>
        <AuthProvider>
          <ToastContainer />
          <Toaster />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/:categoryId" element={<Category />} />

          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;