import { useToast } from "../components/ui/use-toast";

export const useToastHandler = () => {
    const { toast } = useToast();

    const showToast = (title, description, variant = 'default') => {
        toast({
            title,
            description,
            duration: 2000,
            variant,
        });
    };

    return showToast;
};
