import { useState, useContext, createContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const navigate = useNavigate();

    const logIn = async (email, password) => {
        const response = await fetch('https://admin.perfettocucinaitaliana.ro/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to log in');
        }

        const data = await response.json();
        setSession(data);
        localStorage.setItem('authToken', data.accessToken); // Store token in local storage
        return data;
    };

    const logOut = () => {
        setSession(null);
        localStorage.removeItem('authToken'); // Remove token from local storage
        navigate('/login');
    };

    const getSession = useCallback(async () => {
        const token = localStorage.getItem('authToken');
        if (!token) return null;

        const response = await fetch('https://admin.perfettocucinaitaliana.ro/api/session', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch session');
        }

        const data = await response.json();
        setSession(data);
        return data;
    }, []);

    return (
        <AuthContext.Provider value={{ session, setSession, logIn, logOut, getSession }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};