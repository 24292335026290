import React from 'react';

const LoadingComponent = () => {
    return (
        <div className="fullscreen-loader">
            <div className="loader-circle"></div>
        </div>
    );
};

export default LoadingComponent;