/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Button, Drawer, Label, TextInput } from "flowbite-react";
import { Bars3Icon, Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import LoadingComponent from '../../components/loading';
import { useAuth } from '../../hooks/use-auth';

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const customDrawer = {
    "root": {
        "base": "fixed z-[60] overflow-y-auto bg-white p-4 transition-transform dark:bg-gray-800",
        "backdrop": "fixed inset-0 z-[55] bg-gray-900/50 dark:bg-gray-900/80",
        "position": {
            "right": {
                "on": "right-0 top-0 h-screen w-full lg:w-1/3 transform-none",
                "off": "right-0 top-0 h-screen w-full lg:w-1/3 translate-x-full"
            }
        }
    }
};

const Layout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState();
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [newSubcategory, setNewSubcategory] = useState({ enName: '', roName: '' });
    const [currentCategoryId, setCurrentCategoryId] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const currentId = pathSegments[pathSegments.length - 1];
    const token = localStorage.getItem('authToken');


    const { logOut, getSession } = useAuth();

    useEffect(() => {
        const authenticateAndFetchUser = async () => {
            try {
                const session = await getSession();
                if (!session) {
                    navigate('/login');
                    return;
                }
                setUser(session.user);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch session or user data:', error);
                navigate('/login');
            }
        };

        authenticateAndFetchUser();
    }, [navigate, getSession]);

    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`https://admin.perfettocucinaitaliana.ro/api/subcategories?categoryId=${categoryId}`);
            const data = await response.json();
            setSubcategories(prevState => ({
                ...prevState,
                [categoryId]: data.subcategories || []
            }));
        } catch (error) {
            console.error(`Failed to fetch subcategories for categoryId ${categoryId}:`, error);
            setSubcategories(prevState => ({
                ...prevState,
                [categoryId]: []
            }));
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`https://admin.perfettocucinaitaliana.ro/api/categories`);
                const data = await response.json();
                setCategories(data.categories || []);
                data.categories.forEach(category => fetchSubcategories(category.id));
            } catch (error) {
                console.error('Failed to fetch categories:', error);
                setCategories([]);
            }
        };

        fetchCategories();
    }, []);

    const handleAddSubcategory = (categoryId) => {
        setCurrentCategoryId(categoryId);
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
        setNewSubcategory({ enName: '', roName: '' });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://admin.perfettocucinaitaliana.ro/api/subcategories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`

                },
                body: JSON.stringify({ ...newSubcategory, categories: currentCategoryId })
            });

            if (!response.ok) {
                throw new Error('Failed to create subcategory');
            }

            handleDrawerClose();
            fetchSubcategories(currentCategoryId);
        } catch (error) {
            console.error('Failed to create subcategory:', error);
        }
    };

    const handleOnLogOut = async () => {
        try {
            await logOut();
            navigate('/login');
        } catch (error) {
            console.log(error);
        }
    };

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <div className='relative overflow-hidden'>
            <Drawer open={isDrawerOpen} onClose={handleDrawerClose} position="right" theme={customDrawer}>
                <Drawer.Header title="Add Subcategory" titleIcon={() => <></>} />
                <Drawer.Items>
                    <form onSubmit={handleFormSubmit}>
                        <div className="mt-3 mb-6">
                            <Label htmlFor="roName" className="block mb-2">
                                Nume | RO
                            </Label>
                            <TextInput
                                id="roName"
                                name="roName"
                                placeholder="Nume romana"
                                value={newSubcategory.roName}
                                onChange={(e) => setNewSubcategory({ ...newSubcategory, roName: e.target.value })}
                            />
                        </div>
                        <div className="mb-6">
                            <Label htmlFor="enName" className="block mb-2">
                                Nume | EN
                            </Label>
                            <TextInput
                                id="enName"
                                name="enName"
                                placeholder="Nume engleza"
                                value={newSubcategory.enName}
                                onChange={(e) => setNewSubcategory({ ...newSubcategory, enName: e.target.value })}
                            />
                        </div>
                        <div className="my-6">
                            <Button color='light' type="submit" className="w-full">
                                Adauga
                            </Button>
                        </div>
                    </form>
                </Drawer.Items>
            </Drawer>

            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-white grow gap-y-5">
                                    <div className="flex items-center h-16 shrink-0">
                                        <img className="w-auto h-10" src={`${process.env.PUBLIC_URL}/BearDesk-Logo-I.png`} alt="Your Company" />
                                    </div>
                                    <nav className="flex flex-col flex-1">
                                        <ul role="list" className="flex flex-col flex-1 gap-y-7">
                                            {categories.length > 0 && categories.map((category) => (
                                                <li key={category.id}>
                                                    <div className="flex justify-between text-xs font-semibold leading-6 text-gray-400">
                                                        <span>{category.enName}</span>
                                                        <button onClick={() => handleAddSubcategory(category.id)} className="text-indigo-600 hover:underline">Add</button>
                                                    </div>
                                                    <ul role="list" className="mt-2 -mx-2 space-y-1">
                                                        {subcategories[category.id]?.length > 0 && subcategories[category.id].map((sub) => (
                                                            <li key={sub.id}>
                                                                <Link
                                                                    to={`/${encodeURIComponent(sub.id)}`}
                                                                    className={classNames(
                                                                        sub.id === Number(currentId) ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                    )}
                                                                >
                                                                    <span className="truncate cursor-pointer">{sub.enName}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                        <li className="mt-auto">
                                            <a href="#" className="flex p-2 -mx-2 text-sm font-semibold leading-6 text-gray-700 rounded-md group gap-x-3 hover:bg-gray-50 hover:text-indigo-600">
                                                <Cog6ToothIcon className="w-6 h-6 text-gray-400 shrink-0 group-hover:text-indigo-600" aria-hidden="true" />
                                                Settings
                                            </a>
                                        </li>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-white border-r border-gray-200 grow gap-y-5">
                    <div className="flex items-center h-16 shrink-0">
                        <img className="w-auto h-10" src={`${process.env.PUBLIC_URL}/BearDesk-Logo-I.png`} alt="Your Company" />
                    </div>
                    <nav className="flex flex-col flex-1">
                        <ul role="list" className="flex flex-col flex-1 gap-y-7">
                            {categories.length > 0 && categories.map((category) => (
                                <li key={category.id}>
                                    <div className="flex justify-between text-xs font-semibold leading-6 text-gray-400">
                                        <span>{category.enName}</span>
                                        <button onClick={() => handleAddSubcategory(category.id)} className="text-indigo-600 hover:underline">Add</button>
                                    </div>
                                    <ul role="list" className="mt-2 -mx-2 space-y-1">
                                        {subcategories[category.id]?.length > 0 && subcategories[category.id].map((sub) => (
                                            <li key={sub.id}>
                                                <Link
                                                    to={`/${encodeURIComponent(sub.id)}`}
                                                    className={classNames(
                                                        sub.id === Number(currentId) ? 'bg-gray-200 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                    )}
                                                >
                                                    <span className="truncate cursor-pointer">{sub.enName}</span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="lg:pl-72">
                <div className="sticky top-0 z-40 lg:mx-auto lg:max-w lg:px-1">
                    <div className="flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm gap-x-4 sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
                        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                        </button>
                        <div className="w-px h-6 bg-gray-200 lg:hidden" aria-hidden="true" />
                        <div className="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
                            <form className="relative flex flex-1" action="#" method="GET">
                                <label htmlFor="search-field" className="sr-only">Search</label>
                                <MagnifyingGlassIcon className="absolute inset-y-0 left-0 w-5 h-full text-gray-400 pointer-events-none" aria-hidden="true" />
                                <input
                                    id="search-field"
                                    className="block w-full h-full py-0 pl-8 pr-0 text-gray-900 border-0 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Search..."
                                    type="search"
                                    name="search"
                                />
                            </form>
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />
                                <Menu as="div" className="relative">
                                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>
                                        <span className="hidden lg:flex lg:items-center">
                                            <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{user.name}</span>
                                            <ChevronDownIcon className="w-5 h-5 ml-2 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Menu.Button>
                                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                <button className='block px-3 py-1 text-sm leading-6 text-gray-900 bg-gray-50' onClick={handleOnLogOut}>
                                                    Logout
                                                </button>
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>

                <main className="py-10">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8">
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Layout;
