import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useToast } from "../components/ui/use-toast";

function Login() {
    const { toast } = useToast();
    const navigate = useNavigate();
    const { logIn, getSession } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const authenticateAndFetchUser = async () => {
            try {
                const session = await getSession();
                if (session) {
                    navigate('/');
                }

            } catch (error) {
                console.error(error);
            }
        };

        authenticateAndFetchUser();
    }, [navigate, getSession]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await logIn(email, password);
            toast({
                title: "Login succeeded",
                description: "You are now logged in successfully",
                duration: 2000,
            });
            navigate('/'); // Redirect after setting the session
        } catch (error) {
            toast({
                title: "Login Failed",
                description: error.message,
                duration: 2000,
                variant: 'destructive'
            });
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                <h1 className="mb-4 text-xl font-bold text-center">Login</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                        <input
                            type="email"
                            id="email"
                            className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>
                        <input
                            type="password"
                            id="password"
                            className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Log In
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;